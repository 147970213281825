<template>
  <div v-show="config.can_return_payment">
    <h4>DEVOLUCION</h4>
    <div class="form-group">
      <label for="">Monto</label>
      <app-input-money 
        :selCurrDis="true" 
        :quantity.sync="amount" 
        :moneyCode="money_code"></app-input-money>
    </div>
    <label for="">Observacion</label>
    <app-textarea v-model="observation"></app-textarea>
    <app-button-submit @click="save()" text="Devolver"></app-button-submit>
  </div>
</template>

<script>
import {mapState} from "vuex";
import { DentalClinicService } from '../service';

export default {
  computed: {
    ...mapState({
      config: s=> s.config.service.treatments
    })
  },
  props: {
    treatment_plan_id: {},
    money_code: {}
  },
  data: () => ({
    amount: undefined,
    observation: undefined,
payment_method_id: undefined,
  }),
  methods: {
    save() {
      DentalClinicService.returnTPPayment({
        treatment_plan_id: this.treatment_plan_id,
        amount: this.amount,
        payment_method_id: 1,
        observation: this.observation
      }).then(res => {
        this.$emit('submitted', res);
      })
    }
  }
}
</script>

<style>

</style>
