<template>
  <div>
    <h4>TRANSFERIR ABONOS LIBRES</h4>

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: menu == 1 }"
          href="#"
          @click.prevent="menu = 1"
          >Tratamiento</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: menu == 2 }"
          href="#"
          @click.prevent="menu = 2"
          >Financiacion</a
        >
      </li>
    </ul>

    <form v-show="menu == 1" @submit.prevent="save()">
      <div class="form-group">
        <label for>Cliente</label>
        <SelectClient v-model="client_id"></SelectClient>
      </div>
      <div class="form-group">
        <label for>Plan de Tratamiento</label>
        <SelectTreatment
          :client_id="client_id"
          v-model="target_treatment_plan_id"
        ></SelectTreatment>
      </div>
      <div class="form-group">
        <label for>Monto</label>
        <app-input-money
          :selCurrDis="true"
          :quantity.sync="amount"
          :moneyCode="money_code"
        ></app-input-money>
      </div>
      <app-button-submit>Transferir</app-button-submit>
    </form>
    <div v-show="menu == 2">
      <TransferFreeBonusFinancing
        :treatment_plan_id="treatment_plan_id"
        @submitted="$emit('submitted')"
        :money_code="money_code"
      ></TransferFreeBonusFinancing>
    </div>
  </div>
</template>

<script>
import SelectTreatment from "./Select";
import SelectClient from "../../store-module/clients/Select";
import { DentalClinicService } from "../service";
import TransferFreeBonusFinancing from "./TransferFreeBonusFinancing";

export default {
  components: { SelectTreatment, SelectClient, TransferFreeBonusFinancing },
  props: {
    treatment_plan_id: {},
    money_code: {}
  },
  data: () => ({
    menu: 1,
    client_id: undefined,
    target_treatment_plan_id: undefined,
    amount: undefined
    // money_code: undefined
  }),
  methods: {
    save() {
      DentalClinicService.transferTreatmentPayment({
        treatment_plan_id: this.treatment_plan_id,
        target_treatment_plan_id: this.target_treatment_plan_id,
        amount: this.amount
        // money_code: this.money_code
      }).then(() => this.$emit("submitted"));
    }
  }
};
</script>

<style>
</style>
