<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <router-link 
        :to="'/dental-clinic/financings/' + value" 
        class="input-group-text btn btn-light"
        :class="{'disabled': !value}">
        <i class="fa fa-link"></i>
      </router-link>
    </div>
    <select v-model="valueChild" class="custom-select">
    <option v-for="l in list" :key="l.id" :value="l.id">
      <span>#{{ l.treatment_plan_id }} - {{ l.treatment_plan_name }} </span>
      <app-span-money :quantity="l.amount" :moneyCode="l.money_code"></app-span-money>&nbsp;
      (deuda)<app-span-money class="text-info" :quantity="l.amount - l.amount_paid" :moneyCode="l.money_code"></app-span-money>
    </option>
  </select>
  </div>
</template>

<script>
import { DentalClinicService } from '../service';
export default {
  props: {
    client_id: {default: undefined},
    value: {},
    treatment_plan_id: {default: undefined}
  },
  watch: {
    client_id() {
      this.getList();
    }
  },
  data: () => ({
    list: []
  }),
  mounted() {
    // this.getList();
  },
  computed: {
    valueChild: {
      get() { return this.value; },
      set(val) { this.$emit('input', val); }
    }
  },
  methods: {
    getList() {
      DentalClinicService.getFinancings({
        client_id: this.client_id,
        treatment_plan_id: this.treatment_plan_id
      }).then(res => {
        this.list = res.data;
      });
    }
  }
}
</script>

<style>

</style>
