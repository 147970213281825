<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Cliente</label>
      <SelectClient
        ref="selCli"
        :disabled="!canSetClient"
        v-model="client_id"
      ></SelectClient>
    </div>
    <div class="form-group">
      <label for>Nombre del tratamiento</label>
      <input
        type="text"
        class="form-control"
        v-model="name"
        maxlength="255"
        list="listSpecialties"
      />
      <datalist id="listSpecialties">
        <option value="ODONTOLOGIA"></option>
        <option value="ORTODONCIA"></option>
        <option value="PEDIATRIA"></option>
        <option value="IMPLANTES"></option>
      </datalist>
    </div>
    <!-- <div class="form-group">
      <label for>Especialidad</label>
      <SelectSpecialty
        v-model="specialty_id"
        :specialty_id="config.specialty_id"
      ></SelectSpecialty>
    </div> -->
    <div class="form-group">
      <label for>Profesional encargado</label>
      <SelectEmployee
        ref="selEmp"
        v-model="employee_id"
        :specialties="config.specialty_id"
      ></SelectEmployee>
    </div>
    <app-button-submit></app-button-submit>
  </form>
</template>

<script>
import SelectClient from "../../store-module/clients/Select";
import SelectEmployee from "../../admin-module/employees/Select";
import { DentalClinicService } from "../service";
import { mapState } from "vuex";
// import SelectSpecialty from "../../general-module/specialties/Select";

export default {
  components: {
    SelectClient,
    SelectEmployee,
    // SelectSpecialty
  },
  computed: {
    ...mapState({
      config: s => s.config.dental.treatments
    })
  },
  props: {
    canSetClient: { default: true }
  },
  data: () => ({
    // reg: {},
    // canSetClient: true,

    id: 0,
    client_id: 0,
    specialty_id: 0,
    name: "",
    employee_id: 0
  }),
  methods: {
    load(reg) {
      this.id = reg.id;
      this.client_id = reg.client_id;
      this.specialty_id = reg.specialty_id;
      this.name = reg.name;
      this.employee_id = reg.employee_id;

      this.$refs.selCli.setValueFromId(reg.client_id);
      this.$refs.selEmp.setValueFromId(reg.employee_id);
    },
    reset() {
      this.id = null;
      this.client_id = null;
      this.specialty_id = null;
      this.name = null;
      this.employee_id = null;

      // this.reg = {};
      this.$refs.selCli.reset();
      this.$refs.selEmp.reset();
    },
    setClientId(id) {
      this.$refs.selCli.setValueFromId(id);
      // this.canSetClient = false;
    },
    save() {
      if (this.specialty_id == 0) this.specialty_id = undefined;
      DentalClinicService.saveTreatmentPlan(this.$data).then(res =>
        this.$emit("submitted", {
          ...res,
          url: `/dental-clinic/patients/${this.client_id}/treatment-plans/${res}`
        })
      );
    }
  }
};
</script>

<style></style>
