var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-md-6"},[_c('router-link',{attrs:{"to":'/dental-clinic/patients/' +
              _vm.reg.client_id +
              '/treatment-plans/' +
              _vm.reg.id}},[_c('h4',[_vm._v(_vm._s(_vm.reg.name))])]),_c('div',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.reg.initiated),expression:"!reg.initiated"}],staticClass:"badge badge-warning mr-1"},[_vm._v(" No Iniciado ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.reg.ended),expression:"reg.ended"}],staticClass:"badge badge-warning mr-1"},[_vm._v(" Finalizado ")]),_c('div',[_c('b',[_vm._v("Profesional: ")]),_c('span',[_vm._v(_vm._s(_vm.reg.employee_name))])])])],1),_c('div',{staticClass:"col"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ml-auto"},[_c('div',{staticClass:"mb-1"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.config.edit),expression:"config.edit"}],staticClass:"btn btn-light btn-sm d-print-none",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-bars"})]),_c('div',{staticClass:"dropdown-menu"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.$refs.dEdit.show();
                    _vm.$refs.elFormCreate.load(_vm.reg);}}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" Editar ")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.elEliminar.show()}}},[_c('i',{staticClass:"fa fa-trash"}),_vm._v(" Eliminar ")])])])])]),_c('div',{staticClass:"row justify-content-end text-center pr-3"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.reg.payment > 0),expression:"reg.payment > 0"}],staticClass:"col col-lg-4 text-white bg-success p-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$refs.dTransPay.show()}}},[_c('b',[_vm._v("ABONOS LIBRES")]),_c('br'),_c('app-span-money',{attrs:{"quantity":_vm.reg.payment,"moneyCode":_vm.reg.money_code}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.reg.prepaid_amount != 0),expression:"reg.prepaid_amount != 0"}],staticClass:"col col-lg-4 text-white bg-success p-2"},[_c('b',[_vm._v("ABONOS ASIGNADOS")]),_c('br'),_c('app-span-money',{attrs:{"quantity":_vm.reg.prepaid_amount,"moneyCode":_vm.reg.money_code}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.debt_amount > 0),expression:"debt_amount > 0"}],staticClass:"col col-lg-4 text-white bg-danger p-2"},[_c('b',[_vm._v("DEUDA")]),_c('br'),_c('app-span-money',{attrs:{"quantity":_vm.reg.debt_amount,"moneyCode":_vm.reg.money_code}})],1)])])]),_c('div',{staticClass:"d-print-none"},[_c('app-progressbar',{staticClass:"mt-2",attrs:{"value":_vm.perProgress}}),_c('div',{staticClass:"text-muted text-right"},[_vm._v("% "+_vm._s(_vm.perProgress))])],1)]),_c('app-modal-basic',{ref:"dTransPay"},[_c('TransferPayment',{attrs:{"money_code":_vm.reg.money_code,"treatment_plan_id":_vm.reg.id},on:{"submitted":function($event){_vm.$refs.dTransPay.hide();
        _vm.$emit('changePlan');}}}),_c('ReturnPayment',{attrs:{"money_code":_vm.reg.money_code,"treatment_plan_id":_vm.reg.id},on:{"submitted":function($event){_vm.$refs.dTransPay.hide();
        _vm.$emit('changePlan');}}})],1),_c('app-modal-basic',{ref:"dEdit"},[_c('FormCreate',{ref:"elFormCreate",attrs:{"canSetClient":false},on:{"submitted":function($event){_vm.$refs.dEdit.hide();
        _vm.$emit('changePlan');}}})],1),_c('app-modal-yn',{ref:"elEliminar",on:{"yes":function($event){return _vm.deletePlan()}}},[_c('p',[_vm._v("Eliminar este tratamiento?")]),_c('span',[_vm._v("Selecciona el plan al cual transferir los tratamientos")]),_c('SelectTreatment',{ref:"selTre",staticClass:"my-2",attrs:{"client_id":_vm.reg.client_id,"specialtyId":_vm.reg.specialty_id}}),_c('p',{staticClass:"alert alert-info mt-2"},[_vm._v(" Selecciona un tratamiento de la misma especialidad ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }