<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-6 col-md-6">
          <router-link
            :to="
              '/dental-clinic/patients/' +
                reg.client_id +
                '/treatment-plans/' +
                reg.id
            "
          >
            <!-- <h4>#{{ reg.id }} - {{ reg.name }}</h4> -->
            <h4>{{ reg.name }}</h4>
            <!-- <h4>{{ reg.specialty_name }}</h4> -->
          </router-link>

          <!-- <div class="text-muted" v-show="reg.initiated">
            Fecha de inicio {{ reg.initiated_date }}
          </div>
          <div class="text-muted">Fecha Creación: {{ reg.created_at }}</div> -->
          <div>
            <span class="badge badge-warning mr-1" v-show="!reg.initiated">
              No Iniciado
            </span>

            <span class="badge badge-warning mr-1" v-show="reg.ended">
              Finalizado
            </span>
            <!-- <span
              class="badge badge-secondary mr-1"
              v-show="reg.specialty_name"
            >
              {{ reg.specialty_name }}
            </span> -->
            <div>
              <b>Profesional: </b>
              <span>{{ reg.employee_name }}</span>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="d-flex">
            <div class="ml-auto">
              <div class="mb-1">
                <button
                  v-show="config.edit"
                  type="button"
                  class="btn btn-light btn-sm d-print-none"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-bars"></i>
                </button>
                <div class="dropdown-menu">
                  <a
                    href="#"
                    @click.prevent="
                      $refs.dEdit.show();
                      $refs.elFormCreate.load(reg);
                    "
                    class="dropdown-item"
                  >
                    <i class="fa fa-edit"></i> Editar
                  </a>

                  <a
                    href="#"
                    @click.prevent="$refs.elEliminar.show()"
                    class="dropdown-item"
                  >
                    <i class="fa fa-trash"></i> Eliminar
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-end text-center pr-3">
            <div
              v-show="reg.payment > 0"
              class="col col-lg-4 text-white bg-success p-2"
              @click="$refs.dTransPay.show()"
              style="cursor: pointer"
            >
              <b>ABONOS LIBRES</b>
              <br />
              <app-span-money
                :quantity="reg.payment"
                :moneyCode="reg.money_code"
              ></app-span-money>
            </div>
            <div
              v-show="reg.prepaid_amount != 0"
              class="col col-lg-4 text-white bg-success p-2"
            >
              <b>ABONOS ASIGNADOS</b>
              <br />
              <app-span-money
                :quantity="reg.prepaid_amount"
                :moneyCode="reg.money_code"
              ></app-span-money>
            </div>
            <div
              v-show="debt_amount > 0"
              class="col col-lg-4 text-white bg-danger p-2"
            >
              <b>DEUDA</b>
              <br />
              <app-span-money
                :quantity="reg.debt_amount"
                :moneyCode="reg.money_code"
              ></app-span-money>
            </div>
          </div>
        </div>
      </div>
      <div class="d-print-none">
        <app-progressbar class="mt-2" :value="perProgress"></app-progressbar>
        <div class="text-muted text-right">% {{ perProgress }}</div>
      </div>
    </div>

    <app-modal-basic ref="dTransPay">
      <TransferPayment
        @submitted="
          $refs.dTransPay.hide();
          $emit('changePlan');
        "
        :money_code="reg.money_code"
        :treatment_plan_id="reg.id"
      ></TransferPayment>
      <ReturnPayment
        :money_code="reg.money_code"
        :treatment_plan_id="reg.id"
        @submitted="
          $refs.dTransPay.hide();
          $emit('changePlan');
        "
      ></ReturnPayment>
    </app-modal-basic>
    <app-modal-basic ref="dEdit">
      <FormCreate
        ref="elFormCreate"
        :canSetClient="false"
        @submitted="
          $refs.dEdit.hide();
          $emit('changePlan');
        "
      ></FormCreate>
    </app-modal-basic>

    <app-modal-yn ref="elEliminar" @yes="deletePlan()">
      <p>Eliminar este tratamiento?</p>
      <span>Selecciona el plan al cual transferir los tratamientos</span>
      <SelectTreatment
        ref="selTre"
        class="my-2"
        :client_id="reg.client_id"
        :specialtyId="reg.specialty_id"
      ></SelectTreatment>
      <p class="alert alert-info mt-2">
        Selecciona un tratamiento de la misma especialidad
      </p>
    </app-modal-yn>
  </div>
</template>

<script>
import TransferPayment from "./TransferPayment";
import ReturnPayment from "./ReturnPayment";
import { DentalClinicService } from "../service";
// import SelectEmployee from "../../admin-module/employees/Select";
import SelectTreatment from "./Select";
import { mapState } from "vuex";
// import SelectSpecialty from "../../general-module/specialties/Select";
import FormCreate from "./FormCreate";

export default {
  components: {
    TransferPayment,
    ReturnPayment,
    // SelectEmployee,
    SelectTreatment,
    // SelectSpecialty,
    FormCreate
  },
  data: () => ({ changeName: false }),
  props: {
    reg: {},
    details: {
      default: () => []
    }
  },
  methods: {
    save() {
      DentalClinicService.saveTreatmentPlan({
        ...this.reg
      }).then(() => {
        this.$emit("changePlan");
        this.$refs.dEdit.hide();
      });
    },
    deletePlan() {
      let plan_id = this.$refs.selTre.val.id;
      DentalClinicService.deleteTreatmentPlan(this.reg.id, {
        treatment_plan_id: plan_id
      }).then(() => {
        this.$router.push({
          path: `/dental-clinic/patients/${this.reg.client_id}/treatment-plans/${plan_id}`
        });
      });
    }
  },
  computed: {
    ...mapState({
      config: s => s.config.service.treatments
    }),
    free_bonus() {
      return this.reg.debt_amount > this.reg.payment ? 0 : this.reg.payment;
    },
    debt_amount() {
      return this.reg.debt_amount > this.reg.payment
        ? this.reg.debt_amount - this.reg.payment
        : this.reg.debt_amount;
    },
    perProgress() {
      let val = (100 * this.reg.count_maked) / this.reg.count_details;
      return val ? parseInt(val) : 0;
    }
  }
};
</script>

<style></style>
