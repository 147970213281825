<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for="">Cliente</label>
      <SelectClient v-model="client_id"></SelectClient>
    </div>
    <div class="form-group">
      <label for="">Financiacion</label>
      <SelectFinancing
        :client_id="client_id"
        v-model="financing_id"
      ></SelectFinancing>
    </div>
    <div class="form-group">
      <label for="">Monto</label>
      <app-input-money
        :moneyCode="money_code"
        :selCurrDis="true"
        :quantity.sync="amount"
      ></app-input-money>
    </div>
    <!-- <div class="form-group">
      <label for=""></label>
    </div> -->
    <app-button-submit></app-button-submit>
  </form>
</template>

<script>
import SelectFinancing from "../financings/Select";
import { DentalClinicService } from "../service";
import SelectClient from "../../store-module/clients/Select";

export default {
  components: {
    SelectFinancing,
    SelectClient
  },
  props: {
    treatment_plan_id: {},
    money_code: {}
  },
  data: () => ({
    client_id: undefined,
    financing_id: undefined,
    amount: undefined
  }),
  methods: {
    save() {
      DentalClinicService.payDebtsFromTreatmentPayment({
        ...this.$data,
        treatment_plan_id: this.treatment_plan_id
      }).then(
        () => this.$emit("submitted"),
        err => {
          this.$store.dispatch("showError", err.response.data.message);
        }
      );
    }
  }
};
</script>

<style>
</style>
